.try-banner {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 165px 45px;
  max-width: 950px;
  align-self: center;
  z-index: 1;

  &-title {
    color: white;
    max-width: 800px;
    font-size: 4rem;
    margin: auto;
    line-height: 1.1;
    font-weight: bold;

    @media (max-width: 450px) {
      font-size: 1.75rem;
    }

    @media only screen and (min-width: 950px) and (max-width: 1449px) {
      font-size: 3.125rem;
      max-width: 640px;
    }

    @media (max-width: 600px) {
    }
  }

  &-subtitle {
    color: white;
    font-size: 1.625rem;
    font-weight: normal;
    margin: 1rem auto;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
  }
}
