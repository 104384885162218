.try-it-form {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
  max-width: 950px;
  padding-top: 0.85rem;
  align-self: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  .input-btn {
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .input-label {
      max-width: 500px;
      position: relative;

      label {
        position: absolute;
        top: 6px;
        -webkit-transition: font 0.1s ease, top 0.1s ease,
          -webkit-transform 0.1s ease;
        transition: font 0.1s ease, top 0.1s ease, -webkit-transform 0.1s ease;
        -o-transition: font 0.1s ease, top 0.1s ease, -o-transform 0.1s ease;
        -moz-transition: font 0.1s ease, top 0.1s ease, transform 0.1s ease,
          -moz-transform 0.1s ease;
        left: 10px;
        color: #8c8c8c;
        font-weight: 700;
        font-size: 13px;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }

      input {
        min-width: 500px;
        height: 70px !important;
        border: solid 1px #8c8c8c;
        padding: 5px 10px 0;
        font-size: 1rem;
        border-radius: 2px;

        &:focus {
          border-color: #0071eb;
          outline: 0;

          &:invalid {
            border-bottom: 2px solid var(--warning-orange);
          }

          &:valid {
            border-bottom: 2px solid var(--warning-green);
          }
        }

        &:placeholder-shown + label {
          position: absolute;
          top: 50%;
          left: 10px;
          color: #8c8c8c;
          font-weight: 400;
          font-size: 16px;
          -webkit-transition: font 0.1s ease, top 0.1s ease,
            -webkit-transform 0.1s ease;
          transition: font 0.1s ease, top 0.1s ease, -webkit-transform 0.1s ease;
          -o-transition: font 0.1s ease, top 0.1s ease, -o-transform 0.1s ease;
          -moz-transition: font 0.1s ease, top 0.1s ease, transform 0.1s ease,
            -moz-transform 0.1s ease;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        @media (max-width: 770px) {
          width: 100%;
          min-width: 0;
        }
      }

      @media (max-width: 770px) {
        width: 100%;
        max-width: none;
        min-width: 200px;
      }
    }

    @media (max-width: 770px) {
      flex-wrap: wrap;
      justify-content: center;
      min-width: 200px;
    }
  }

  .try-button {
    display: flex;
    align-items: center;
    height: 70px;
    background: var(--primary-red);
    color: white;
    text-transform: capitalize;
    padding: 0 32px;
    font-weight: 400;
    border: 0;
    cursor: pointer;
    border-top-left-radius: 0;
    border-left: 1px solid #333;
    font-size: 1.875rem;
    min-height: 70px;
    min-width: 74px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 45%);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.45);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 45%);
    letter-spacing: 0.1px;
    text-decoration: none;

    img {
      margin-left: 10px;
      filter: brightness(0) invert(1);
      width: 24px;

      @media (max-width: 1000px) {
        width: 16px;
      }
    }

    &:hover {
      background: #f40612;
    }

    @media (max-width: 770px) {
      margin-top: 20px;
    }

    @media (max-width: 430px) {
      height: 50px;
      font-size: 16px;
      margin-top: 20px;
      font-weight: bold;
    }
  }

  h3 {
    color: white;
    max-width: none;
    padding: 0 5%;
    font-size: 1.2rem;
    margin: 0 auto;
    font-weight: 400;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 22px;
    }

    @media only screen and (min-width: 950px) and (max-width: 1449px),
      only screen and (min-width: 1450px) {
      max-width: none;
      padding-bottom: 20px;
      font-size: 1.2rem;
    }
  }
}
