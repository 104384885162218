.card-tall {
  background: #333;
  width: 16%;
  max-width: 18rem;
  height: auto;
  margin-right: 5px;
  cursor: pointer;
  transform-origin: center;
  border-radius: 4px;
  transition: 250ms all cubic-bezier(0.42, 0.13, 1, 1);
  transition-delay: 250ms;
  position: relative;

  &:hover {
    transform: scale(1.2);
    transition: all 500ms;
    z-index: 1;
  }

  &:last-of-type {
    transform-origin: right center;
  }
  &:first-of-type {
    transform-origin: left center;
  }

  &-content {
    width: 100%;
    height: 100%;
    transition: all 500ms;

    &-img {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: fill;
      }
      video {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    &-video {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
      }
    }
  }

  &-overlay {
    position: absolute;
    bottom: 1%;
    left: 5%;
    color: white;

    &-icons {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 1em;

      div {
        margin: 0 2.5px;
      }

      svg {
        min-width: 2rem;
        min-height: 2rem;
        max-width: 3rem;
        max-height: 3rem;
      }
    }

    &-title {
      line-height: 1.4;
      font-weight: 700;
      text-shadow: 0 1px 1px #000000b3;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;

      white-space: pre-line;
      margin: 0.5em 0 0.3em;
      font-size: 1.3vw;
    }
  }
}

.card-tall-content-video-speaker {
  right: 2%;
  top: 1%;
}
