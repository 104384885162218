.login-form {
  display: flex;
  flex-direction: column;
  min-height: 660px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  max-width: 450px;
  padding: 60px 68px 40px;
  margin: 90px auto 100px;

  &-title {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 28px;
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 100%;

    .login-form-input {
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;

      .login-form-input-group {
        position: relative;

        input {
          background: #333;
          border-radius: 4px;
          border: 0;
          color: #fff;
          height: 50px;
          line-height: 50px;
          padding: 16px 20px 0;
          width: 100%;
          font-size: inherit;

          &:focus {
            outline: none;

            &:invalid {
              border-bottom: 2px solid #e87c03;
              -webkit-border-bottom-left-radius: 4px;
              -moz-border-radius-bottomleft: 4px;
              border-bottom-left-radius: 4px;
              -webkit-border-bottom-right-radius: 4px;
              -moz-border-radius-bottomright: 4px;
              border-bottom-right-radius: 4px;
            }
            &:valid {
              border-bottom: 2px solid #5fa53f;
              -webkit-border-bottom-left-radius: 4px;
              -moz-border-radius-bottomleft: 4px;
              border-bottom-left-radius: 4px;
              -webkit-border-bottom-right-radius: 4px;
              -moz-border-radius-bottomright: 4px;
              border-bottom-right-radius: 4px;
            }
          }

          &:focus ~ label,
          &:valid ~ label {
            top: 7px;
            transform: translateY(0);
            font-size: 11px;
            color: #8c8c8c;
          }
        }

        label {
          position: absolute;
          top: 50%;
          left: 20px;
          color: #8c8c8c;
          font-size: 1rem;
          -webkit-transition: font 0.1s ease, top 0.1s ease,
            -webkit-transform 0.1s ease;
          -o-transition: font 0.1s ease, top 0.1s ease, -o-transform 0.1s ease;
          -moz-transition: font 0.1s ease, top 0.1s ease, transform 0.1s ease,
            -moz-transform 0.1s ease;
          transition: font 0.1s ease, top 0.1s ease, transform 0.1s ease,
            -webkit-transform 0.1s ease, -moz-transform 0.1s ease,
            -o-transform 0.1s ease;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }

      &-error {
        padding: 6px 3px;
        margin: 0;
        color: var(--error-orange);
        font-size: 0.8rem;
      }
    }

    .submit {
      background: #e50914;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      margin: 24px 0 12px;
      padding: 16px;
      border: 0;
      color: white;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &-text {
    color: #737373;
    font-size: 16px;
    font-weight: 500;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &-small {
      margin-top: 10px;
      font-size: 13px;
      line-height: normal;
      color: #8c8c8c;

      a {
        background-color: transparent;
        border: none;
        color: #0071eb;
        cursor: pointer;
        display: inline;
        font-family: inherit;
        font-size: inherit;
        padding: 0;
        text-decoration: none;
      }
    }
  }
}
