:root {
  --primary-red: #e50914;
  --primary-red-hover: #f40612;
  --warning-green: #5fa53f;
  --warning-orange: #ffa00a;
  --error-orange: #e87c03;
  --bg-color: #000;
}

@font-face {
  font-family: 'Netflix Sans';
  font-weight: 100;
  font-display: optional;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Th.woff2) format('woff2'),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Th.woff) format('woff')
}

@font-face {
  font-family: 'Netflix Sans';
  font-weight: 300;
  font-display: optional;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Lt.woff2) format('woff2'),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Lt.woff) format('woff')
}

@font-face {
  font-family: 'Netflix Sans';
  font-weight: 400;
  font-display: optional;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Rg.woff2) format('woff2'),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Rg.woff) format('woff')
}

@font-face {
  font-family: 'Netflix Sans';
  font-weight: 700;
  font-display: optional;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Md.woff2) format('woff2'),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Md.woff) format('woff')
}

@font-face {
  font-family: 'Netflix Sans';
  font-weight: 800;
  font-display: optional;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Bd.woff2) format('woff2'),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Bd.woff) format('woff')
}

@font-face {
  font-family: 'Netflix Sans';
  font-weight: 900;
  font-display: optional;
  src: url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Blk.woff2) format('woff2'),url(https://assets.nflxext.com/ffe/siteui/fonts/netflix-sans/v3/NetflixSans_W_Blk.woff) format('woff')
}

*,*:before,*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Netflix Sans',sans-serif,-apple-system;
  background-color: var(--bg-color);
}

a {
  font-weight: 700;
  text-decoration: none;
  display: block;
  text-transform: none;
  width: 100%;
  color: #fff;
}
