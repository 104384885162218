.faq {
  display: flex;
  border-bottom: 8px solid #222;
  flex-direction: column;
  padding-bottom: 70px;

  &-title {
    font-size: 50px;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 8px;
    color: white;
    text-align: center;
    margin: auto;
  }

  &--inside {
    display: flex;
    flex-direction: column;
    //max-width: 815px;
    margin: auto;
    padding: 70px 45px 0;

    &-border {
      margin-bottom: 40px;
    }
  }
}
