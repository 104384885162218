.play {
  position: fixed;
  width: 50vw;
  left: 25%;
  height: 50vh;
  z-index: 5;

  &-container {
    position: relative;

    &-icon {
      &-close {
        position: absolute;
        width: 2rem;
        height: 2rem;
        right: 2%;
        top: 2%;
        z-index: 2;
      }
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
}
