.profile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: #141414;
  z-index: 0;

  &-pick {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    &-container {
      max-width: 80%;
      background: #141414;

      &-manage-profile {
        display: block;
        margin: 0 auto;
        font-size: 1.2vw;
        border: 1px solid grey;
        color: grey;
        text-transform: uppercase;
        padding: 0.5em 1.5em;
        letter-spacing: 2px;
        cursor: pointer;
        background-color: transparent;
        max-width: 70%;

        &:hover {
          color: #fff;
          outline: 3px solid white;
        }
      }
    }

    &-title {
      width: 100%;
      color: #fff;
      font-size: 3.5vw;
    }

    &-list {
      padding: 0;
      margin: 2em 0;
      -webkit-transition: opacity 0.4s ease-out;
      -o-transition: opacity 0.4s ease-out;
      -moz-transition: opacity 0.4s ease-out;
      transition: opacity 0.4s ease-out;

      &-item {
        list-style-type: none;
        text-align: center;
        margin: 0 2vw 0 0;
        width: 10vw;
        max-width: 200px;
        min-width: 84px;
        display: inline-block;
        vertical-align: top;
        position: relative;

        &:hover > &-avatar {
          box-shadow: 0 0 1px 2px #ddd;
        }

        &:hover &-name {
          font-weight: bold;
          color: white;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &-avatar {
          height: 10vw;
          width: 10vw;
          max-height: 200px;
          max-width: 200px;
          min-height: 84px;
          min-width: 84px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          position: relative;
          text-decoration: none;
          background-repeat: no-repeat;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          background-size: cover;
          background-color: #333;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
        }

        &-name {
          color: #808080;
          font-size: 1.5rem;
          text-overflow: ellipsis;

          &:hover {
            font-weight: bold;
            color: #e5e5e5;
          }
        }
      }
    }
  }
}
