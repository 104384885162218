.browse {
  background-color: #141414;

  &--header {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;

    &-icon {
      &-speaker {
        position: absolute;
        right: 5%;
        top: 75%;
        width: 5%;
        height: 5%;
      }
    }

    &-cover-img {
      position: absolute;
      object-fit: cover;
      object-position: right center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      opacity: 1;
      -webkit-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      -o-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      -moz-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
    }

    &-cover-video {
      position: absolute;
      object-fit: cover;
      object-position: right center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      opacity: 1;
      -webkit-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      -o-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      -moz-transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
      transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
    }

    &-feature {
      position: absolute;
      top: 0;
      bottom: 35%;
      left: 4%;
      width: 36%;
      z-index: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -moz-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      a {
        display: flex;
      }

      align-items: normal;

      &-poster {
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }

      &-title {
        line-height: normal;
        color: #fff;
        font-weight: 400;
        width: 100%;
        font-size: 1.4vw;
        text-shadow: 2px 2px 4px #00000073;
        margin: 0.9vw 0 0 0;
      }

      &-play {
        box-shadow: 0 0.6vw 1vw -0.4vw rgba(0, 0, 0, 0.35);
        background-color: white;
        color: black;
        border-width: 0;
        border-radius: 5px;
        max-width: 130px;
        font-weight: bold;
        font-size: 20px;
        margin-top: 15px;
        cursor: pointer;
        transition: background-color 150ms ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba(255, 255, 255, 0.75);
        }

        &-svg {
          height: 1.8rem;
          width: 1.8rem;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-right: 10px;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &-text {
          font-family: "Netflix Sans", "Helvetica Neue", Helvetica, Arial,
            sans-serif;
          font-size: 1rem;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: block;
          font-weight: bold;
          line-height: 2.4rem;
        }
      }
    }

    &-shadows {
      background: -webkit-linear-gradient(
        13deg,
        rgba(0, 0, 0, 0.6) 0,
        rgba(0, 0, 0, 0) 85%
      );
      background: -moz-
        oldlinear-gradient(13deg, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0) 85%);
      background: -o-linear-gradient(
        13deg,
        rgba(0, 0, 0, 0.6) 0,
        rgba(0, 0, 0, 0) 85%
      );
      background: linear-gradient(
        77deg,
        rgba(0, 0, 0, 0.6) 0,
        rgba(0, 0, 0, 0) 85%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 50%;
      bottom: 0;
      opacity: 1;
      -webkit-transition: opacity 0.5s;
      -o-transition: opacity 0.5s;
      -moz-transition: opacity 0.5s;
      transition: opacity 0.5s;
    }
  }
}
