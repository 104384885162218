.header--navbar {
  display: flex;
  padding: 0 50px;
  height: 70px;
  align-items: center;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(10%, rgba(0, 0, 0, 0.7)),
    color-stop(10%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-
    oldlinear-gradient(to top, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );

  a {
    display: flex;
  }

  &-logo {
    height: 35px;
    width: 135px;
    margin-right: 40px;

    @media (min-width: 1450px) {
      height: 45px;
      width: 165px;
    }
  }

  &-sign-in {
    background-color: var(--primary-red);
    display: block;
    width: 85px;
    height: auto;
    color: white;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    padding: 7px 17px;
    font-weight: 400;
    font-size: 1rem;

    &:hover {
      background: var(--primary-red-hover);
    }
  }
}
