.header {
  display: flex;
  flex-direction: column;
  height: 769.781px;

  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.35)
    ),
    url("../../Assets/images/header-bg.jpg") center / cover no-repeat;

  &--banner {
    padding: 150px 0 500px 0;
    flex-direction: column;
    align-items: normal;
    width: 50%;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  &--overlay {
    position: absolute;
    height: 775.781px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.8)),
      color-stop(60%, rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 0.8))
    );
    background-image: -webkit-linear-gradient(
      bottom,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
    background-image: -moz-
      oldlinear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 0,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.8) 100%
      );
    background-image: -o-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
    border-bottom: 8px solid #222;
  }
}
