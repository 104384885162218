.navbar-left {
  color: white;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &-item {
    list-style-type: none;
    margin-left: 18px;

    a {
      position: relative;
      color: #e5e5e5;
      -webkit-transition: color 0.4s;
      -o-transition: color 0.4s;
      -moz-transition: color 0.4s;
      transition: color 0.4s;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #b3b3b3;
      }
    }
  }
}
