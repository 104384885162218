.login {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &-bg-image {
    object-fit: cover;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.35)
      )
      center;
    overflow: hidden;
    position: fixed;
    opacity: 0.5;
    z-index: -1;
  }

  &--footer {
    max-width: none;
    margin-top: 0;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
  }
}
