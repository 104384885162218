.features-item {
  display: flex;
  border-bottom: 8px solid #222;
  padding: 50px 5%;
  color: white;
  overflow: hidden;

  &--container-inside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1100px;
    margin: auto;
    width: 100%;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
  &--panel {
    width: 50%;

    &-title {
      font-size: 3.125rem;
      line-height: 1.1;
      margin-bottom: 8px;

      @media (max-width: 600px) {
        font-size: 35px;
      }
    }

    &-subtitle {
      font-size: 26px;
      font-weight: normal;
      line-height: normal;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    &-img {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 1000px) {
      width: 100%;
      padding: 0 45px;
      text-align: center;
    }

    &-img-video {
      margin: -10% -5% -5% 0;
      position: relative;
      overflow: hidden;
      z-index: -1;

      video {
        width: 100%;
        height: 100%;
        max-width: 73%;
        max-height: 54%;
        position: absolute;
        top: 46%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      img {
        position: relative;
        z-index: 2;
      }
    }
  }
}

.mobile-part {
  @media (min-width: 1000px) {
    margin: -8% 0 -4% -15%;
    min-height: 100px;
    width: 100%;
    flex-direction: row-reverse;
  }
}

.stranger-animation {
  position: absolute;
  text-align: left;
  left: 50%;
  bottom: 8%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0 auto;
  background: #000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60%;
  min-width: 15em;
  padding: 0.25em 0.65em;
  border: 2px solid rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: 0 0 2em 0 #000;
  -moz-box-shadow: 0 0 2em 0 #000;
  box-shadow: 0 0 2em 0 #000;
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  border-radius: 0.75em;
  z-index: 2;

  @media only screen and (min-width: 950px) and (max-width: 1449px),
    only screen and (min-width: 1450px) {
    .animation-card.downloadAndWatch .our-story-card-animation {
      padding: 0.5em 0.75em;
    }
  }

  &-image {
    margin: 0 1em 0 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;

    img {
      height: 5em;
      @media only screen and (min-width: 550px) and (max-width: 949px) {
        height: 4em;
      }

      @media only screen and (min-width: 950px) and (max-width: 1449px) {
        height: 4.5em;
      }
    }
  }

  &-text {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    margin: 0.3em 0;

    :last-child {
      color: #0071eb;
    }
  }

  &::after {
    width: 3em;
    height: 3em;
    outline: 2px solid #000;
    outline-offset: -2px;
    display: block;
    background: url(../../Assets/images/download-icon.gif) center center
      no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100%;
    background-size: 100%;
    content: "";
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}

.custom-reverse-direction {
  flex-direction: row-reverse;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
