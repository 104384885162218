.navbar-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: absolute;
  right: 4%;
  top: 0;
  height: 100%;

  &-element:not(:last-child) {
    margin-right: 15px;
  }

  &-element {
  }
  &-profile {
    display: block;
    position: relative;
    font-size: 12px;
    z-index: 0;

    &-dropdown {
      width: 100%;
      cursor: pointer;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      img {
        vertical-align: middle;
        height: 32px;
        width: 32px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
      }

      &:hover &-caret {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &-caret {
        margin-left: 10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #fff transparent transparent transparent;
        -webkit-transition: -webkit-transform 367ms
          cubic-bezier(0.21, 0, 0.07, 1);
        transition: -webkit-transform 367ms cubic-bezier(0.21, 0, 0.07, 1);
        -o-transition: -o-transform 367ms cubic-bezier(0.21, 0, 0.07, 1);
        -moz-transition: transform 367ms cubic-bezier(0.21, 0, 0.07, 1),
          -moz-transform 367ms cubic-bezier(0.21, 0, 0.07, 1);
        transition: transform 367ms cubic-bezier(0.21, 0, 0.07, 1);
      }
    }

    &:hover &-sub-dropdown {
      visibility: visible;
      opacity: 1;
      transition: all 150ms;
    }

    &-sub-dropdown {
      visibility: hidden;
      position: absolute;
      transition: all 150ms;
      opacity: 0;
      right: 0;
      top: 52px;
      width: 181px;
      margin-left: 0;
      padding: 0;
      background-color: #1f1f1fe6;

      &-profiles {
        height: auto;
        padding: 10px 0 5px 0;
        overflow: hidden;
        margin: 0;

        &-item {
          line-height: 32px;
          padding: 5px 10px;
          display: block;
          font-size: 13px;
          cursor: default;

          div {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            img {
              margin-right: 10px;
              vertical-align: middle;
              height: 32px;
              width: 32px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              border-radius: 4px;
              display: inline;
            }

            span {
              overflow: hidden;
              -o-text-overflow: ellipsis;
              text-overflow: ellipsis;
              width: 100px;
              line-height: 18px;
              vertical-align: middle;
              display: inline-block;
              white-space: pre;
              color: white;
            }
          }
        }
      }

      &-menu {
        padding: 10px 0;
        border-top: solid 1px rgba(255, 255, 255, 0.25);
        margin: 0;

        &-item {
          padding: 5px 10px;
          display: block;
          font-size: 13px;
          line-height: 16px;

          a {
            font-weight: 700;
            text-decoration: none;
            display: block;
            text-transform: none;
            width: 100%;
            color: #fff;
          }
        }
      }
    }
  }
}
